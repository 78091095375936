import React from "react";
import "./styles/Home.css";

const Home = () => {
  return (
    <div className="home">
      <h1 className="home-title">TjWit Media</h1>
    </div>
  );
};

export default Home;
