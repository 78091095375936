import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import Preloader from "./components/Preloader/Preloader";
import Home from "./pages/Home";
import Booking from "./pages/Booking";
import Client from "./pages/Client";
import Portfolio from "./pages/Portfolio";
import ClientPage from "./pages/ClientPage";
import "./App.css";
import "./fonts/Shoal-Bold.ttf";

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Simulate content loading
    const timer = setTimeout(() => setLoading(false), 2000);
    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  const handleLogin = () => setIsAuthenticated(true);

  return (
    <>
      <Preloader isVisible={loading} />
      {!loading && (
        <Router>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/booking" element={<Booking />} />
            <Route path="/client" element={<Client onLogin={handleLogin} />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route
              path="/client/:clientID"
              element={
                isAuthenticated ? <ClientPage /> : <Navigate to="/client" />
              }
            />
          </Routes>
        </Router>
      )}
    </>
  );
}

export default App;
