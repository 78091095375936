import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./styles/Client.css";
import clientData from "../utils/clientData.json";

const Client = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = (e) => {
    e.preventDefault();

    // Find matching client data
    const client = clientData.find(
      (c) => c.clientEmail === email && c.clientPassword === password
    );

    if (client) {
      onLogin(); // Update authentication state
      navigate(`/client/${client.clientID}`); // Redirect to client page
    } else {
      setError("Invalid email or password. Please try again.");
    }
  };

  return (
    <div className="client-login">
      <h1>Welcome!</h1>
      <p>
        Please login with your email and the album password provided to you by
        TjWit Media.
      </p>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="login-input"
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
          required
        />
        <button type="submit" className="login-button">
          Login
        </button>
      </form>
    </div>
  );
};

export default Client;
