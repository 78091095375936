import React from "react";
import "./Preloader.css";

const Preloader = ({ isVisible }) => {
  return (
    <div className={`preloader ${isVisible ? "visible" : "hidden"}`}>
      <div className="spinner"></div>
    </div>
  );
};

export default Preloader;
