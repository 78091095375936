import React, { useState } from "react";
import "./styles/Booking.css";
import emailjs from "@emailjs/browser";

const questions = [
  { id: 1, type: "text", question: "What is your name?", required: true },
  {
    id: 2,
    type: "email",
    question: "What is your email address?",
    required: true,
    validate: (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value), // Email validation
  },
  {
    id: 3,
    type: "choice",
    question: "What type of photography session are you looking for?",
    options: ["Sports", "Family", "Portrait", "Candid"],
    required: true,
  },
  {
    id: 4,
    type: "choice",
    question: "Is this sports session for a single player or the entire team?",
    condition: (answers) => answers[3] === "Sports",
    options: ["Single Player", "Entire Team"],
    required: true,
  },
  {
    id: 5,
    type: "text",
    question: "What is the player's name?",
    condition: (answers) => answers[4] === "Single Player",
    required: true,
  },
  {
    id: 6,
    type: "number",
    question: "What is the player's number?",
    condition: (answers) => answers[4] === "Single Player",
    required: true,
    validate: (value) => !isNaN(value) && Number.isInteger(Number(value)), // Must be an integer
  },
  {
    id: 7,
    type: "text",
    question: "What is the team's name?",
    condition: (answers) => answers[4] === "Entire Team",
    required: true,
  },
  {
    id: 8,
    type: "text",
    question: "What event or game will I be photographing?",
    condition: (answers) => answers[3] === "Sports",
    required: true,
  },
  {
    id: 9,
    type: "text",
    question: "Where will the session take place?",
    condition: (answers) =>
      ["Family", "Portrait", "Candid"].includes(answers[3]),
    required: true,
  },
  {
    id: 10,
    type: "text",
    question: "Do you have a specific theme or style in mind?",
    condition: (answers) =>
      ["Family", "Portrait", "Candid"].includes(answers[3]),
  },
  {
    id: 11,
    type: "date",
    question: "Select a date for your session.",
    required: true,
  },
  {
    id: 12,
    type: "text",
    question:
      "Any additional details you'd like to share? (Time, special requests, etc.)",
  },
];

const Booking = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [animationClass, setAnimationClass] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState(false); // Success state

  const handleAnswer = (value) => {
    const currentQuestion = questions[currentStep];

    // Validation logic
    if (currentQuestion.required && !value.trim()) {
      setErrorMessage("This field is required.");
      return;
    }

    if (currentQuestion.validate && !currentQuestion.validate(value)) {
      setErrorMessage("Invalid input. Please follow the specified format.");
      return;
    }

    setErrorMessage("");
    setAnswers((prev) => ({ ...prev, [currentQuestion.id]: value }));

    let nextStep = currentStep + 1;
    while (
      nextStep < questions.length &&
      questions[nextStep].condition &&
      !questions[nextStep].condition({
        ...answers,
        [currentQuestion.id]: value,
      })
    ) {
      nextStep++;
    }

    if (nextStep < questions.length) {
      setAnimationClass("slide-out-left");
      setTimeout(() => {
        setCurrentStep(nextStep);
        setAnimationClass("slide-in-right");
      }, 300);
    } else {
      const finalAnswers = { ...answers, [currentQuestion.id]: value };
      sendEmail(finalAnswers);
    }
  };

  const sendEmail = (finalAnswers) => {
    let emailID = Math.floor(Math.random() * 100);

    let dataString = "";
    for (let key in finalAnswers) {
      if (finalAnswers.hasOwnProperty(key)) {
        dataString += finalAnswers[key] + "\n \n";
      }
    }

    const data = {
      emailID: `${emailID}`,
      formData: `${dataString.trim()}`,
      userName: `${finalAnswers[1]}`,
      userEmail: `${finalAnswers[2]}`,
    };

    emailjs.init({
      publicKey: "uDHtkIMnNaTr6pJ9Z",
    });

    emailjs.send("service_jtd83vf", "template_n6099vr", data).then(
      () => {
        setSuccess(true); // Show success message
      },
      (error) => {
        console.log(error.text);
        alert("There was an error submitting your booking. Please try again.");
      }
    );
  };

  const goBack = () => {
    let prevStep = currentStep - 1;
    while (
      prevStep >= 0 &&
      questions[prevStep].condition &&
      !questions[prevStep].condition(answers)
    ) {
      prevStep--;
    }

    if (prevStep >= 0) {
      setAnimationClass("slide-out-right");
      setTimeout(() => {
        setCurrentStep(prevStep);
        setAnimationClass("slide-in-left");
      }, 300);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      if (
        questions[currentStep].type === "text" ||
        questions[currentStep].type === "email" ||
        questions[currentStep].type === "number"
      ) {
        handleAnswer(e.target.value);
      }
    }
  };

  return (
    <div className="booking-container">
      {success && ( // Success Modal
        <div className="success-modal">
          <div className="success-content">
            <h2>🎉 Booking Submitted Successfully! 🎉</h2>
            <p>
              Thank you for your response! Please check your inbox for a
              confirmation email, and we will be in touch shortly!
            </p>
            <a className="done-btn" href="/">
              Home
            </a>
          </div>
        </div>
      )}

      <div className="progress-bar">
        <div
          className="progress"
          style={{ width: `${((currentStep + 1) / questions.length) * 100}%` }}
        ></div>
      </div>

      <div className={`question-card ${animationClass}`}>
        <p className="question">{questions[currentStep].question}</p>

        {errorMessage && <p className="error-message">{errorMessage}</p>}

        {questions[currentStep].type === "text" && (
          <input
            type="text"
            placeholder="Type your answer..."
            onKeyDown={handleKeyDown}
          />
        )}

        {questions[currentStep].type === "email" && (
          <input
            type="email"
            placeholder="Type your email..."
            onKeyDown={handleKeyDown}
          />
        )}

        {questions[currentStep].type === "number" && (
          <input
            type="number"
            placeholder="Type your answer..."
            onKeyDown={handleKeyDown}
          />
        )}

        {questions[currentStep].type === "choice" && (
          <div className="choice-options">
            {questions[currentStep].options.map((option) => (
              <button
                key={option}
                onClick={() => handleAnswer(option)}
                className="choice-button"
              >
                {option}
              </button>
            ))}
          </div>
        )}

        {questions[currentStep].type === "date" && (
          <input type="date" onChange={(e) => handleAnswer(e.target.value)} />
        )}
      </div>

      <div className="navigation-buttons">
        {currentStep > 0 && (
          <button className="back-button" onClick={goBack}>
            Back
          </button>
        )}
        {questions[currentStep].type !== "choice" && (
          <button
            className="forward-button"
            onClick={() =>
              handleAnswer(
                document.querySelector(".question-card input")?.value || ""
              )
            }
          >
            {currentStep === questions.length - 1 ? "Submit" : "Next"}
          </button>
        )}
      </div>
    </div>
  );
};

export default Booking;
