import React from "react";
import { useParams } from "react-router-dom";
import "./styles/ClientPage.css";
import clientData from "../utils/clientData.json";

const ClientPage = () => {
  const { clientID } = useParams();

  const client = clientData.find((c) => c.clientID === clientID);

  if (!client) {
    return <p>Client not found!</p>;
  }

  return (
    <div className="client-page">
      <h1>Welcome, {client.clientName}!</h1>
      <p>Your albums are listed below:</p>
      <div className="albums-container">
        {client.albums.map((album, index) => (
          <div className="album-card" key={index}>
            <h2>{album.albumName}</h2>
            <a href={album.albumLink} target="_blank" rel="noopener noreferrer">
              <img
                src={album.albumPicture}
                alt={album.albumName}
                className="album-image"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ClientPage;
